import.meta.glob([
    '../img/**',
]);

import axios from 'axios';

window.axios = axios;

import Pikaday from 'pikaday';

window.Pikaday = Pikaday;

import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://0d4d05187266400baf0a9fa340698dd9@o4505602835873792.ingest.sentry.io/4505602861760512",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", "https://lense.app/camera/"],
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.01, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    enabled: import.meta.env.PROD,
});

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.withCredentials = true; // without this one it wasn't working in Firefox
window.axios.defaults.withXSRFToken = true;

